<template>
  <div :id="'app'" :style="{height:this.height}">
    <router-view></router-view>
    <!-- <div class="interface">
      <span>Number of Videos</span>
      <input type="number" v-model="numberOfVideos">
      <span>Wait Time in Milliseconds</span>
      <input type="number" name="waitTime" v-model="waitTime">
      <span>Restart when full?</span>
      <input type="checkbox" id="checkbox" v-model="restartWhenFull">
      <button type="button" name="button" @click="reRender">Restart</button>
    </div> -->
    <!--  <VideoWrapper :key="renderKey" :waitTime="waitTimeArray" :numberOfVideos="Number(numberOfVideos)" :restartWhenFull="restartWhenFull" />-->
  </div>
</template>

<script>
export default {
  name: 'VideoComponent',
  data() {
    return {
      height: 0
    }
  },
  methods: {
    getInnerHeight() {
      this.height = window.innerHeight + "px"
    },
    resizeHandler() {
      location.reload()
    }
  },
  mounted() {
    this.getInnerHeight()
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
}
</script>

<style lang="scss">
@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  width: 100vw;

  a {
    cursor: url("assets/cursor.png") 15 15, auto !important;
    text-decoration: none;
  }
}

html {
  height: -webkit-fill-available;
}

#app {
  background: linear-gradient(
          180deg, #0000ff, transparent) no-repeat;
  display: flex;
  //align-items: center;
  justify-content: center;
  width: 100vw;
  cursor: url("assets/cursor.png") 15 15, auto;
  scroll-behavior: smooth;
  position: relative;
  padding: 0 25px;
  font-size: 16px;

  @include from(1920px) {
    font-size: 20px;
  }
}

.interface {
  padding-bottom: 2em;
}

input {
  margin-right: 2em;
}
</style>
