<template>
  <div class="footer">
    <div class="footer__content-wrapper footer__content-wrapper--start">
      <div @click="toggleImprint" class="footer__link-wrapper">
        <span class="footer__link-text footer__link-text--start">imprint</span>
        <img src="../assets/quit.png"
             class="footer__image-close footer__image-close--start footer__image-close--hidden">
      </div>
      <div class="imprint-content imprint-content--hidden">
        <div class="footer__paragraph-wrapper"><span class="footer__label">imprint</span></div>
        <div class="footer__paragraph-wrapper"><span class="footer__label">team</span></div>

        <div class="footer__paragraph-wrapper">
          <a href="https://www.malteuchtmann.com" target="_blank" class="footer__paragraph-link-wrapper" style="display: inline">
            <div class="normal footer__link">Malte Uchtmann</div>
          </a>
          <p>Malte Uchtmann initiated the project and worked on the concept and design. He is a photographer and
            multidisciplinary artist currently based in Hanover, Germany.</p>
        </div>

        <div class="footer__paragraph-wrapper">
          <a href="https://p.bobolakis.de" target="_blank" class="footer__paragraph-link-wrapper" style="display: inline">
            <div class="normal footer__link">Panagiotis Bobolakis</div>
          </a>
          <p>Panagiotis Bobolakis is a freelance web developer located in Berlin. He continued and finalized the
            development part of this project, which Artur started.</p>
        </div>

        <div class="footer__paragraph-wrapper">
          <a href="http://www.arturneufeld.com" target="_blank" class="footer__paragraph-link-wrapper" style="display: inline">
            <div class="normal footer__link">Artur Neufeld</div>
          </a>
          <p>Artur Neufeld wrote the code for the basic functions of the webpage. He is a web developer, designer and
            artist specializing in interactive web experiences.</p>
        </div>

        <div class="footer__paragraph-wrapper">
          <a href="https://www.maxheinemann.com" target="_blank" class="footer__paragraph-link-wrapper" style="display: inline">
            <div class="normal footer__link">Max Heinemann</div>
          </a>
            <span>&</span>
          <a href="http://www.gesakrieter.com" target="_blank" class="footer__paragraph-link-wrapper" style="display: inline">
            <div class="normal footer__link">Gesa Kriter</div>
          </a>
          <p>Max Heinemann and Gesa Krieter worked together on the design. Max Heinemann is a graphic designer with a
            focus on editorial design and visual identities. Gesa Krieter is a communication designer focussing on book
            design and visual concepts for print, web and social media.</p>
        </div>
      </div>
    </div>

    <div v-if="showVideoControls" class="footer__video-menu">
      <router-link to="/video" v-if="this.withAudio" class="footer__video-menu-link">
        <img src="../assets/sound.png" alt="">
        <span>off</span>
      </router-link>
      <router-link v-else to="/video#audio" class="footer__video-menu-link">
        <img src="../assets/sound.png" alt="">
        <span>sound</span>
      </router-link>
      <a :href="this.pathname" class="footer__video-menu-link">
        <img src="../assets/quit.png" alt="">
        <span>quit</span>
      </a>
      <div @click="handleRestart" class="footer__video-menu-link">
        <img src="../assets/restart.png" alt="">
        <span>restart</span>
      </div>
    </div>
    <div v-else class="footer__start">
      <router-link class="footer__link-wrapper link" to="/video">
        start
      </router-link>
    </div>

    <div class="footer__content-wrapper footer__content-wrapper--end">
      <div @click="toggleInfo" class="footer__link-wrapper">
        <span class="footer__link-text footer__link-text--end">info</span>
        <img src="../assets/quit.png" class="footer__image-close footer__image-close--end footer__image-close--hidden">
      </div>
      <div class="info-content info-content--hidden">
        <div class="footer__paragraph-wrapper"><span class="footer__label">info</span></div>
        <div class="footer__paragraph-wrapper">
          <p>Can’t stop staring at yourself in video calls?</p>
          <p>"A Video Call With Yourself" is an interactive website that explores the psychological and social effects
            of viewing oneself in video calls.</p>
          <p>At least since the onset of the Covid-19 pandemic, video calls have become part of everyday life for many
            people. Much of the professional and social interaction has been and still is happening in video calls as a
            result of lockdowns and home office. Partially daily, people see others, but more importantly themselves,
            via webcams.</p>
          <p>It's like a constant mirror - you can supposedly see yourself as others see you. It's not the image of you
            that you know from the bathroom mirror or pictures and videos. You see how you interact, what gestures and
            movements you make, how you react to others. It seems as if you have yourself as a counterpart.</p>
          <p>But to constantly look at yourself from the outside does something to you. One evaluates and classifies
            oneself. It is a form of constant self-monitoring and self-control of one's presentation.</p>
          <p>Self-presentation plays an increasingly important role in today's world. In times of selfies and
            self-representation via social media, everyone wants to be the master of their public image. To have control
            over one's external impression, however, one must also control oneself. Especially in a live performance, it
            is therefore important to convince yourself of the image you provide and to check on the effect of your
            staging again and again.</p>
          <p>The metaphor of the panopticon has since Michelle Foucault's "Discipline and Punish" (1975) been used as a
            common model of the principle of order in modern surveillance societies. In the concept developed in the
            early 19th century by Jeremy Bantham for the construction of prisons, the simultaneous surveillance of
            several persons by a single supervisor is made possible. This is done by constructing the cells in such a
            way that the supervisor can see all cells at any time, but the prisoners cannot always see the supervisor
            and thus do not know whether they are currently being seen. Accordingly, they are permanently exposed to the
            feeling of possible surveillance. The fact that surveillance is invisible lets one control individual
            behavior even when it is not being monitored. Self-monitoring and disciplining in this way have become the
            main source of social control in modern society. It is, according to Foucault, an essential component of
            Western liberal disciplinary society and the resulting social conformity of the individual.</p>
          <p>The idea of the panopticon can be applied to video calls. You sit in the middle and countless potential
            observers sit on the outside and look at you - or not. One does not know.</p>
          <p>Gilles Deleuze continues Foucault's ideas on the transition from the disciplinary society to the society of
            control, in his essay "Postscript on the Societies of Control" (1990), writing "I understand the difference
            between the two types of society in terms of power primarily in terms of a shift into the individual. The
            disciplinary society always tried to grasp and influence the individual from the outside (with commands and
            prohibitions). The control society increasingly leaves this laborious work to the individuals
            themselves.”</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      showVideoControls: false,
      withAudio: false,
      pathname: location.pathname
    }
  },
  methods: {
    setVideoControlStatus() {
      if (window.location.href.includes("#/video")) {
        this.showVideoControls = true
        document.getElementsByClassName('info-content')[0].classList.add('page-video')
        document.getElementsByClassName('imprint-content')[0].classList.add('page-video')
      }
      if (window.location.href.includes('/#/video#audio')) {
        this.withAudio = true
      }
    },
    handleAudio() {
      if (window.location.href.includes('/#/video#audio')) {
        window.location.href = window.location.protocol + "//" + window.location.host + '/#/video'
        location.reload()
      } else {
        window.location.href = window.location.protocol + "//" + window.location.host + '/#/video#audio'
        location.reload()
      }

      // let videoElements = document.getElementsByTagName('video');
      // console.log(videoElements);
      // for (let i = 0; i < videoElements.length; i++ ) {
      //   if (videoElements[i].muted === true) {
      //     videoElements[i].muted = false
      //   } else {
      //     videoElements[i].muted = true
      //   }
      // }
    },
    locationObserver() {
      let lastUrl = location.href;
      new MutationObserver(() => {
        const url = location.href;
        if (url !== lastUrl) {
          lastUrl = url;
          onUrlChange();
        }
      }).observe(document, {subtree: true, childList: true});


      function onUrlChange() {
        location.reload()
      }
    }
    ,
    handleRestart() {
      location.reload()
    },
    toggleImprint() {
      let info = document.getElementsByClassName("info-content")[0];
      let imprint = document.getElementsByClassName("imprint-content")[0];
      let linkWrapper = document.getElementsByClassName("footer__link-wrapper")[0];

      if (imprint.classList.contains("imprint-content--hidden")) {
        if (!info.classList.contains("info-content--hidden")) {
          this.toggleInfo();
        }
        linkWrapper.classList.add("footer__link-wrapper--active")
        imprint.classList.remove("imprint-content--hidden")
      } else {
        imprint.classList.add("imprint-content--hidden")
        linkWrapper.classList.remove('footer__link-wrapper--active')
      }
    },
    toggleInfo() {
      let info = document.getElementsByClassName("info-content")[0];
      let imprint = document.getElementsByClassName("imprint-content")[0];
      let linkWrapper = document.getElementsByClassName("footer__link-wrapper")[2];

      if (linkWrapper === undefined) {
        linkWrapper = document.getElementsByClassName("footer__link-wrapper")[1]
      }

      if (info.classList.contains("info-content--hidden")) {
        if (!imprint.classList.contains("imprint-content--hidden")) {
          this.toggleImprint();
        }
        linkWrapper.classList.add("footer__link-wrapper--active")
        info.classList.remove("info-content--hidden")
      } else {
        info.classList.add("info-content--hidden")
        linkWrapper.classList.remove("footer__link-wrapper--active")
      }
    },
    resetTimer() {
      let timeout;
      document.onmousemove = resetTimer;
      document.onclick = resetTimer;

      function resetTimer() {
        clearTimeout(timeout);
        timeout = setTimeout(function(){ window.location.href = location.pathname}, 2*60*1000);
      }
    }
  },
  mounted() {
    this.setVideoControlStatus()
    this.locationObserver()
    // this.resetTimer()
  }
}
</script>

<style lang="scss">


@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

$mobile: 500px;

.footer {

  $base: &;

  position: absolute;
  display: flex;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0 5px 15px;
  color: #0700ff;

  @include from($mobile) {
    padding: 0 25px 15px;
  }

  &__image-close {
    height: 26px;
    padding: 2px;
    position: absolute;
    bottom: 3px;
    opacity: 0;
    transform: rotate(0);

    @include from(1920px) {
      height: 32px;
    }

    &--start {
      left: 3px;

      @include from(1920px) {
        bottom: 1px;
        left: 1px;
      }
    }

    &--end {
      right: 3px;

      @include from(1920px) {
        bottom: 1px;
        right: 1px;
      }
    }
  }

  &__link-text {
    color: #0700ff;
    text-shadow: none;
    transition: ease-in-out 233ms;
    position: absolute;
    height: 29px;
    bottom: 0;
    opacity: 1;
    width: 100px;

    @include from($mobile) {
      width: 115px;
    }

    @include from(1920px) {
      height: 35px;
    }

    &--start {
      left: -2px;
    }

    &--end {
      right: -2px;
    }

    &:hover {
      color: #0700ff;
      text-shadow: 0px 0px 5px #d7ffff;
      transition: ease-in-out 233ms;
    }
  }

  &__paragraph-link-wrapper,
  &__link-wrapper {
    border: solid 2px #0700ff;
    border-radius: 30px;
    transition: ease-in-out 233ms;
    padding: 3px 25px;

    &:hover {
      box-shadow: 0px 0px 30px 10px #d7ffff;
      transition: ease-in-out 233ms;
    }
  }

  &__paragraph-link-wrapper {
    padding: 6.5px 25px;
    margin-bottom: 26px;
  }

  &__link-wrapper {

    position: relative;
    min-height: 36px;
    min-width: 100px;

    @include from($mobile) {
      min-width: 115px;
    }

    @include from(1920px) {
      min-height: 42px;
    }

    &.link {
      padding: 7px 25px;

      @include from(1920px) {
        min-height: 42px;
        min-width: 42px;
      }
    }

    &--active {

      padding: 6px;
      transition: ease-in-out 233ms;
      min-width: 36px;

      @include from(1920px) {
        min-height: 42px;
        min-width: 42px;
      }

      #{$base}__image-close {
        transition: ease-in-out 233ms;
        opacity: 1;
        padding: 3px;
        bottom: 3px;

        &--start {
          transform: rotate(90deg);
          left: 3px;
        }

        &--end {
          transform: rotate(-90deg);
          right: 3px;
        }
      }

      #{$base}__link-text {
        opacity: 0;
        transition: ease-in-out 233ms;
      }
    }
  }

  &__link {
    color: #0700ff;
    text-shadow: none;
    transition: ease-in-out 233ms;
    display: inline-block;

    &:hover {
      color: #0700ff;
      text-shadow: 0px 0px 5px #d7ffff;
      transition: ease-in-out 233ms;
    }
  }

  &__label {
    color: #0700ff;
    padding: 5px 15px;
    border: solid 2px #0700ff;
    border-radius: 30px;
  }

  &__video-menu {
    left: 0;
    display: flex;
    width: 100%;
    height: 7vh;
    flex-direction: row;
    bottom: 0;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    position: relative;
    max-width: 350px;
  }

  &__video-menu-link {

    min-width: 70px;

    img {
      max-height: 35px;
      margin: 0 auto;
    }
  }

  &__content-wrapper {
    min-width: 115px;
    display: flex;

    &--start {
      justify-content: flex-start;
    }

    &--end {
      justify-content: flex-end;
    }
  }

  .info-content,
  .imprint-content {
    position: absolute;
    bottom: 80px;
    width: calc(550px - 75px);
    background: rgba(255, 255, 255, 0.9);
    height: calc(100vh - 165px);
    padding: 20px;
    border-radius: 10px;
    border: solid 2px #0700ff;
    box-shadow: 0px 0px 30px 10px #d7ffff;
    overflow-y: scroll;
    transform: scale(1);
    transition: ease-in 433ms;
    max-width: calc(100% - 10px);
    text-align: center;

    &.page-video {
      bottom: 100px;
      height: calc(100vh - 185px);

      @include from($mobile) {
        height: calc(100vh - 125px);
      }

      @media all and (device-width: 820px) and (device-height: 1180px) {
        height: calc(100vh - 160px);
      }
      @media all and (device-width: 1180px) and (device-height: 820px) {
        height: calc(100vh - 160px);
      }
    }

    @include from($mobile) {
      height: calc(100vh - 105px);
    }

    @media all and (device-width: 820px) and (device-height: 1180px) {
      height: calc(100vh - 140px);
    }
    @media all and (device-width: 1180px) and (device-height: 820px) {
      height: calc(100vh - 140px);
    }

    p {
      text-align: left;
    }

    #{$base}__paragraph-wrapper {

      span {
        margin: 0 10px;
      }

      + #{$base}__paragraph-wrapper {
        margin-top: 30px;
      }

      p {
        margin-top: 15px;
      }
    }

    //@include from($mobile) {
    //  bottom: 110px
    //}

    &--hidden {
      transform: scale(0);
      transition: ease-out 433ms;
    }
  }

  .info-content {
    right: 5px;
    transform-origin: 100% 100%;

    p + p {
      margin-top: 10px;
    }

    @include from($mobile) {
      right: 25px;
    }

    &--hidden {
      transform-origin: 100% 100%;
    }
  }

  .imprint-content {
    left: 5px;
    transform-origin: 0 100%;

    @include from($mobile) {
      left: 25px;
    }

    &--hidden {
      transform-origin: 0 100%;
    }
  }
}

</style>