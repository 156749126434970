<template>
<div class="hello">
  <!-- <video :srcObject="this.webcam[0].src" autoplay>

  </video> -->
  <div>
    <transition-group tag="div" :class="isMirrored ? 'mirrored': ''" id="gallery" name="fade" mode="in-out">
      <div :key="index" v-for="(video, index) in allVideos" class="video-container" v-if="video.show">
        <!-- <div class="video-wrapper"> -->
        <video @loadedmetadata="index == 0 ? webCamLoaded() : null" ref="vid" :key="index" :src="video.isWebcam ? null : video.src" loop autoplay :muted="hasAudio ? false : true" playsinline>

        </video>
        <!-- </div> -->
      </div>

    </transition-group>

  </div>
</div>
</template>

<script>
import RecordRTC from '../../node_modules/recordrtc/RecordRTC.min.js'
import debounce from "lodash/debounce";
const sleep = m => new Promise(r => setTimeout(r, m));

export default {
  name: 'VideoWrapper',
  props: {
    waitTime: Array,
    numberOfVideos: Number,
    restartWhenFull: Boolean
  },
  data() {
    return {
      videoCount: 1,
      aspectRatio: 4 / 3,
      webcam: [{
        show: true,
        src: null,
        isWebcam: true
      }],
      videos: [],
      isMirrored: false,
      hasAudio: false

      // numberOfVideos: 12,
      // waitTime: 1500
    }
  },
  computed: {
    allVideos() {
      let allVideosArray = [
        ...this.webcam, ...this.videos
      ]
      // let count = this.videoCount

      function arrayMove(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
      }
      let index = allVideosArray.findIndex((element => element.isWebcam))
      let b = this.getRandomInt(0, allVideosArray.length)
      console.log(b);
      let randomWebcamArray = arrayMove(allVideosArray, index, b)
      // console.log(allVideosArray.length, randomWebcamArray.length);
      return allVideosArray;
    }
  },
  methods: {
    webCamLoaded() {
      this.aspectRatio = this.$refs.vid[0].videoWidth / this.$refs.vid[0].videoHeight
      this.recalculateLayout()
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    recalculateLayout() {
      const gallery = document.getElementById("gallery");
      const aspectRatio = this.aspectRatio;
      const screenWidth = document.body.getBoundingClientRect().width - 50;
      let screenHeight = document.body.getBoundingClientRect().height - 125;
      // screenHeight = Math.floor(screenHeight * 0.75);
      console.log(screenHeight + ' _____')
      // const videoCount = this.videoCount;
      const videoCount = document.getElementsByTagName("video").length;

      function calculateLayout(containerWidth, containerHeight, videoCount, aspectRatio) {

        let bestLayout = {
          area: 0,
          cols: 0,
          rows: 0,
          width: 0,
          height: 0
        };


        for (let cols = 1; cols <= videoCount; cols++) {
          const rows = Math.ceil(videoCount / cols);
          const hScale = containerWidth / (cols * aspectRatio);
          const vScale = containerHeight / rows;
          let width;
          let height;
          if (hScale <= vScale) {
            width = Math.floor(containerWidth / cols);
            height = Math.floor(width / aspectRatio);
          } else {
            height = Math.floor(containerHeight / rows);
            width = Math.floor(height * aspectRatio);
          }
          const area = width * height;
          if (area > bestLayout.area) {
            bestLayout = {
              area,
              width,
              height,
              rows,
              cols
            };
          }
        }
        return bestLayout;
      }

      const {
        width,
        height,
        cols
      } = calculateLayout(
        screenWidth,
        screenHeight,
        videoCount,
        aspectRatio
      );

      gallery.style.setProperty("--width", width + "px");
      gallery.style.setProperty("--height", height + "px");
      gallery.style.setProperty("--cols", cols + "");

    }
  },
  mounted() {

    if (window.location.href.includes('mirrored')) {
      this.isMirrored = true
      console.log("mirrored");
    }
    if (window.location.href.includes('audio')) {
      this.hasAudio = true
      console.log("has Audio");
    }

    let singleVideo = {
      show: false,
      src: null
    }
    for (var i = 0; i < this.numberOfVideos; i++) {
      this.videos.push({
        show: false,
        src: null
      })
    }

    const debouncedRecalculateLayout = debounce(this.recalculateLayout, 50);
    window.addEventListener("resize", debouncedRecalculateLayout);
    debouncedRecalculateLayout();


    // function getRandomInt(min, max) {
    //   min = Math.ceil(min);
    //   max = Math.floor(max);
    //   return Math.floor(Math.random() * (max - min + 1)) + min;
    // }

    let that = this;
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: that.hasAudio ? true : false
    }).then(async function(stream) {
      let recorder = RecordRTC(stream, {
        type: 'video',
        disableLogs: true
      });
      that.$refs.vid[0].srcObject = stream
      that.webcam[0].src = stream

      let recorder2 = RecordRTC(stream, {
        type: 'video',
        disableLogs: true
      });

      const restartWhenFull = async () => {
        let randomVideoIndex = that.getRandomInt(0, that.numberOfVideos - 1)
        //    console.log(randomVideoIndex);
        that.videos[randomVideoIndex].src = null
        // that.videos[randomVideoIndex].show = false
        // that.videos[randomVideoIndex].show = true
        recorder2.startRecording();
        await sleep(that.waitTime[randomVideoIndex]);
        // that.videos[randomVideoIndex].show = true
        recorder2.stopRecording(function() {
          let blob = recorder.getBlob();
          // console.log(i - 1);
          //  that.$refs.vid[i].src = URL.createObjectURL(blob)
          that.videos[randomVideoIndex].src = URL.createObjectURL(blob)
          // that.$nextTick(() => {
          //
          that.recalculateLayout()
          // })
          //  debouncedRecalculateLayout();
          //    console.log(randomVideoIndex);
          restartWhenFull()
        });
      }

      for (var i = 0; i <= that.numberOfVideos - 1; i++) {
        //console.log(that.$refs.vid[0].videoHeight);
        // that.aspectRatio = that.$refs.vid[0].videoWidth / that.$refs.vid[0].videoHeight
        let hasFinished = false
        recorder.startRecording();


        that.videoCount = i + 1
        await sleep(that.waitTime[i]);
        //  console.log(i);
        that.videos[i].show = true
        recorder.stopRecording(function() {
          let blob = recorder.getBlob();
          //    console.log(i - 1);
          //  that.$refs.vid[i].src = URL.createObjectURL(blob)
          that.videos[i - 1].src = URL.createObjectURL(blob)
          // that.$nextTick(() => {
          //
          that.recalculateLayout()
          // })
          //  debouncedRecalculateLayout();
          //  console.log(i);
          // if (hasFinished) {
          //   i = getRandomInt(0, that.numberOfVideos - 1)
          //   // i = 0
          // }
          // if (that.restartWhenFull && i >= that.numberOfVideos - 1) {
          //   hasFinished = true
          //
          // }
          // if (hasFinished) {
          //   i = getRandomInt(0, that.numberOfVideos - 1)
          //   // i = 0
          // }
          // if (that.restartWhenFull && i == that.numberOfVideos - 1) restartWhenFull()
          if (that.restartWhenFull) i = that.getRandomInt(0, that.numberOfVideos - 2)
          // if (i == that.numberOfVideos - 1) i = 0
        });

      }



    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
$s-min: 20;
$s-max: 70;
$l-min: 30;
$l-max: 90;

@function randomNum($min, $max) {
    $rand: random();
    $randomNum: $min + floor($rand * (($max - $min) + 1));

    @return $randomNum;
}

@for $i from 1 through 13 {
    #gallery .video-container:nth-child(#{$i}):before {
        //background-color: hsl(random(360),$s-min+random($s-max+-$s-min),$l-min+random($l-max+-$l-min));
        background: linear-gradient(180deg, #0000ff, white) no-repeat;
        position: absolute;
        content: "• • •";
      text-shadow: 0px 0px 5px #d7ffff;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        // opacity: 0.75;
        animation: fade-out 233ms ease forwards;
        animation-delay: randomNum(1, 3) * 1s;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 20px;
    }
}

#gallery .video-container:nth-child(1) video {

    animation-delay: 1s;
}

#gallery.mirrored .video-container:nth-child(11) video,
#gallery.mirrored .video-container:nth-child(3) video,
#gallery.mirrored .video-container:nth-child(5) video,
#gallery.mirrored .video-container:nth-child(8) video {
    transform: scaleX(-1);
}


#gallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: calc(var(--width) * var(--cols));
    margin-top: 25px;
    // transition: 0.05s;

}

.video-container {
    transform: scale(.9);
    box-shadow: 0px 0px 14px 0px rgb(215, 255, 255);
    width: var(--width);
    height: var(--height);
    background-color: #3a3a3e;
    position: relative;
    border-radius: 1em;
    overflow: hidden;
    video {

        transition: opacity 3s ease;
        opacity: 1;
    }
    // margin: 2px;
}
.video-container.active video {
    opacity: 1;
}
.video-container.in-active video {
    opacity: 0;
}

video {
    // transition: all 1s linear;
    height: 100%;
    width: 100%;
}
</style>
