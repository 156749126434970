<template>
  <div class="home">
    <div v-if="cameraStatus !== true" class="notification-box notification-box--hidden">
      <p class="notification-text">You have previously {{ this.cameraStatus }} the camera access request. <br/> Please reload this page or reset camera permissions.</p>
    </div>
    <p class="home__tagline home__tagline--hidden">A Videocall With Yourself</p>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/Footer'

export default {
  name: 'HomeComponent',
  data() {
    return {
      cameraStatus: true
    }
  },
  components: {
    Footer
  },
  methods: {
    getPermissions() {
      const constraints = { audio: true, video: true }
      if (this.cameraStatus !== true ) {
        navigator.mediaDevices
            .getUserMedia(constraints)
            .catch(this.handleError);
      }
    },
    handleError(error) {
      let errorString = error.toString();
      console.log(errorString);

      if (errorString.includes('dismissed')) {
        this.cameraStatus = "dismissed"
      } else if (errorString.includes('denied')) {
        this.cameraStatus = "denied"
      }
    },
    removeClass() {
      setTimeout(() => {
        document.getElementsByClassName('home__tagline--hidden')[0].classList.remove('home__tagline--hidden')
      },1000)
    }
  },
  mounted() {
    this.getPermissions()
    this.removeClass()
  }
}
</script>

<style lang="scss" scoped>

@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

.home {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__tagline {
    margin-bottom: 80px;
    text-transform: uppercase;
    font-size: 10vw;
    font-family: 'La-Distorsionada', sans-serif;
    color: #280cff;
    opacity: 1;
    transition: ease-out 1000ms;

    &--hidden {
      opacity: 0;
      transition: ease-out 433ms;
    }

    &:hover {
      text-shadow: 0px 0px 5px #d7ffff;
      transition: ease-out 433ms;
    }

    @include from(1920px) {
      //font-size: 7.5vw;
    }
  }
}
</style>