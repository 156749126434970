import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeComponent from '../views/HomeComponent.vue'
import VideoComponent from '../views/VideoComponent.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeComponent',
    component: HomeComponent
  },
  {
    path: '/video',
    name: 'VideoComponent',
    component: VideoComponent
  }
]

const router = new VueRouter({
  routes
})

export default router
