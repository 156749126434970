<template>
  <div class="video">
    <div class="video-wrapper">
      <VideoWrapper :key="renderKey" :waitTime="waitTimeArray" :numberOfVideos="Number(numberOfVideos)" :restartWhenFull="restartWhenFull" />
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import VideoWrapper from '../components/VideoWrapper'
import Footer from '../components/Footer'

export default {
  name: 'VideoComponent',
  data() {
    return {
      renderKey: 0,
      waitTime: [2000],
      numberOfVideos: 15,
      restartWhenFull: true,
      waitTimeArray: []
    }
  },
  components: {
    VideoWrapper,
    Footer
  },
  methods: {
    reRender() {
      this.renderKey++
    },
  },
  mounted() {
    function randomNumber(min, max) {
      return Math.random() * (max - min) + min;
    }

    for (var i = 0; i < this.numberOfVideos; i++) {
      this.waitTimeArray[i] = Math.ceil(randomNumber(1500, 2500))
    }
  }
}
</script>

<style lang="scss">
.video {
}
.video-wrapper {
}
</style>